import React from "react"
import PropTypes from "prop-types"
import { Field } from "formik"
import styled from "styled-components"

import { Select } from "../select"

const StyledSelect = styled(Select)`
  flex: 1;
  .react-select__option {
    text-transform: capitalize;
  }
  .react-select__indicator,
  .react-select__clear-indicator {
    display: none;
  }
  .react-select__control {
    padding: 0;

    .react-select__value-container {
      padding: 0.375rem;

      .react-select__placeholder {
        margin: 0 0.5rem;
      }

      .react-select__input {
        margin-left: 0.25rem;
      }

      .react-select__multi-value {
        border-radius: 0.375rem;
        margin-right: 0.25rem;

        .react-select__multi-value__label {
          font-weight: 400;
          font-size: 1rem;
          padding: 0.25rem 0.125rem 0.25rem 0.5rem;
          text-transform: capitalize;
        }

        .react-select__multi-value__remove {
          cursor: pointer;
          border-top-right-radius: 0.375rem;
          border-bottom-right-radius: 0.375rem;

          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
  }
`

export const TagSelector = ({
  name,
  note,
  placeholder,
  noOptionsMessage,
  ...props
}) => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)

  const handleInput = (input, form, meta) => {
    if (!input) {
      setMenuIsOpen(false)
      return ""
    }
    const inputSplitByComma = input.split(",")
    if (inputSplitByComma.length > 1) {
      const newWord = inputSplitByComma[0]
      // handle if first char is a comma
      if (!newWord) return ""
      // only add if not already in selected keywords
      if (!meta.value.includes(newWord)) {
        form.setFieldValue(name, [...meta.value, newWord])
        setMenuIsOpen(false)
      }
      // clear input
      return ""
    }
    // else open the menu
    if (!menuIsOpen) setMenuIsOpen(true)
  }

  return (
    <Field name={name}>
      {({ form, field, meta }) => (
        <StyledSelect
          {...props}
          {...field}
          placeholder={placeholder}
          note={note}
          // use same string for value and label
          getOptionLabel={opt => opt}
          getOptionValue={opt => opt}
          menuIsOpen={menuIsOpen}
          isMulti
          noOptionsMessage={noOptionsMessage}
          tabSelectsValue={false}
          openMenuOnClick={false}
          errorText={meta.touched ? meta.error : ""}
          onChange={opt => form.setFieldValue(name, opt || [])}
          onInputChange={input => handleInput(input, form, meta)}
          onBlur={() => form.setFieldTouched(name, true)}
        />
      )}
    </Field>
  )
}

TagSelector.defaultProps = {
  placeholder: "Enter tag",
  note: "",
  options: [],
  noOptionsMessage: () => "No existing tags, type comma to add new",
}

TagSelector.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  note: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string.isRequired),
}
