import React from "react"
import { Col2, Col2Constant } from "../../style/containers"
import SButton from "../../components/button"
import { FormikInput, FormikText, FormikDatepicker } from "../form"

const EmploymentHistory = ({
  values,
  validateForm,
  setFieldTouched,
  setFieldValue,
  initialValues,
  colorTheme,
}) => {
  const addRow = async rep_type => {
    const errors = await validateForm()
    const currentReps = values[rep_type]

    const rep_obj = initialValues.employment_history[0]
    if (errors[rep_type]) {
      // touch all fields of last rep
      const fields = Object.keys(rep_obj)
      return fields.forEach(field => {
        const currentRep = currentReps.length - 1
        const fieldPath = `[${rep_type}][${currentRep}][${field}]`
        setFieldTouched(fieldPath)
      })
    } else {
      // add new rep
      setFieldValue(`[${rep_type}]`, [...currentReps, rep_obj])
    }
  }

  const removeRow = (rep_type, repIndexToRemove) => {
    const reps = values[rep_type]
    // filter out rep by index
    const updatedReps = reps.filter((_, idx) => idx !== repIndexToRemove)
    // clear any touched errors
    setFieldTouched(`[${rep_type}][${repIndexToRemove}]`, false)
    setFieldValue(`[${rep_type}]`, updatedReps)
  }

  return (
    <>
      <p>
        On the following page, please provide details of ALL employment and any
        periods of unemployment since leaving school, beginning with your
        present or most recent job first. Please use continuation sheet if
        necessary. Please also state any voluntary and community work experience
        with (VOL). Please note that any missing or undisclosed employment could
        result in the termination of your application and/or employment.
      </p>
      <p>
        <strong>Please explain any gaps in your employment history.</strong>
      </p>
      {values
        ? values.employment_history.map((_, idx) => (
            <React.Fragment key={`employment_history_[${idx}]`}>
              <Col2>
                <h3>Employment - {idx + 1}</h3>
                <FormikInput
                  label="Company name"
                  name={`employment_history[${idx}].company_name`}
                  type="text"
                />
                <FormikInput
                  label="Job title"
                  name={`employment_history[${idx}].job_title`}
                  type="text"
                />
                <Col2Constant>
                  <FormikDatepicker
                    label="Date from"
                    name={`employment_history[${idx}].date_from`}
                    placeholderText="Select a date"
                    maxDate={new Date()}
                    />
                  <FormikDatepicker
                    label="Date to"
                    name={`employment_history[${idx}].date_to`}
                    placeholderText="Select a date" 
                    minDate={new Date(values.employment_history[idx].date_from)}
                  />
                </Col2Constant>
                <FormikInput
                  label="Reason for leaving"
                  name={`employment_history[${idx}].reason_for_leaving`}
                  type="text"
                />
                <FormikText
                  label="Duties"
                  name={`employment_history[${idx}].duties`}
                />
              </Col2>
              {values.employment_history.length > 1 && idx !== 0 ? (
                <SButton
                  type="button"
                  name="clear-signature"
                  onClick={() => removeRow("employment_history", idx)}
                  red={true}
                >
                  Delete employment
                </SButton>
              ) : null}
              <hr />
              <SButton
                onClick={() => addRow("employment_history")}
                colorTheme={colorTheme}
              >
                Add another employment
              </SButton>
            </React.Fragment>
          ))
        : null}
    </>
  )
}

export default EmploymentHistory
