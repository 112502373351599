import * as Yup from "yup"
import { dateRegex, phoneRegex, standardRequiredText } from "../../constants"

export const validationSchema = Yup.object().shape({
  personal_details: Yup.object().shape({
    lead_source: Yup.string().required(standardRequiredText),
    title: Yup.string().required(standardRequiredText),
    first_name: Yup.string().required(standardRequiredText),
    surname: Yup.string().required(standardRequiredText),
    date_of_birth: Yup.string()
      .matches(dateRegex, "Must be a valid date in the format dd/mm/yyyy")
      .required(standardRequiredText),
    email: Yup.string()
      .email(
        "Must be a valid email address in the format 'example@example.com'"
      )
      .required(standardRequiredText),
    mobile: Yup.string()
      .matches(phoneRegex, "Must be a valid phone number")
      .required(standardRequiredText),
    landline: Yup.string().matches(phoneRegex, "Must be a valid phone number"),
    address: Yup.string().required(standardRequiredText),
    emergency_contact_name: Yup.string().required(standardRequiredText),
    emergency_contact_phone: Yup.string()
      .matches(phoneRegex, "Must be a valid phone number")
      .required(standardRequiredText),
    emergency_contact_relationship: Yup.string().required(standardRequiredText),
    nationality: Yup.string().required(standardRequiredText),
    passport_number: Yup.number()
      .typeError("Must be a number")
      .required(standardRequiredText),
    passport_expiry_date: Yup.string()
      .matches(dateRegex, "Must be a valid date in the format dd/mm/yyyy")
      .required(standardRequiredText),
    has_medical_conditions: Yup.string().required(standardRequiredText),
    medical_conditions_details: Yup.string(),
  }),
  method_of_transport: Yup.array()
    .min(1)
    .required(standardRequiredText),
  employment_history: Yup.object().shape({
    company_1: Yup.object().shape({
      company_name: Yup.string().required(standardRequiredText),
      job_title: Yup.string().required(standardRequiredText),
      date_from: Yup.string()
        .matches(dateRegex, "Must be a valid date in the format dd/mm/yyyy")
        .required(standardRequiredText),
      date_to: Yup.string()
        .matches(dateRegex, "Must be a valid date in the format dd/mm/yyyy")
        .required(standardRequiredText),
      reason_for_leaving: Yup.string(),
      duties: Yup.string(),
    }),
    company_2: Yup.object().shape({
      company_name: Yup.string(),
      job_title: Yup.string(),
      date_from: Yup.string().matches(
        dateRegex,
        "Must be a valid date in the format dd/mm/yyyy"
      ),
      date_to: Yup.string().matches(
        dateRegex,
        "Must be a valid date in the format dd/mm/yyyy"
      ),
      reason_for_leaving: Yup.string(),
      duties: Yup.string(),
    }),
    company_3: Yup.object().shape({
      company_name: Yup.string(),
      job_title: Yup.string(),
      date_from: Yup.string().matches(
        dateRegex,
        "Must be a valid date in the format dd/mm/yyyy"
      ),
      date_to: Yup.string().matches(
        dateRegex,
        "Must be a valid date in the format dd/mm/yyyy"
      ),
      reason_for_leaving: Yup.string(),
      duties: Yup.string(),
    }),
  }),
  health_and_medical_questionnaire: Yup.object().shape({
    eyesight_and_vision: Yup.object().shape({
      any_problems: Yup.string().required(standardRequiredText),
      wears_glasses: Yup.string().required(standardRequiredText),
      problems_corrected_by_glasses: Yup.string().required(
        standardRequiredText
      ),
      colour_blind: Yup.string().required(standardRequiredText),
      difficulty_differentiating_colours: Yup.string().required(
        standardRequiredText
      ),
      has_had_eye_operation: Yup.string().required(standardRequiredText),
      has_had_retina_scan: Yup.string().required(standardRequiredText),
      condition_details: Yup.string(),
    }),
    heart_and_lungs: Yup.object().shape({
      has_suffered_from_high_blood_pressure: Yup.string().required(
        standardRequiredText
      ),
      has_had_stroke_or_heart_attack: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_heart_disease: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_angina: Yup.string().required(standardRequiredText),
      has_had_heart_surgery: Yup.string().required(standardRequiredText),
      has_suffered_from_asthma_bronchitis_or_similar: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_disease_not_mentioned: Yup.string().required(
        standardRequiredText
      ),
      condition_details: Yup.string(),
    }),
    back: Yup.object().shape({
      has_suffered_from_sciatica: Yup.string().required(standardRequiredText),
      has_suffered_from_lumbago: Yup.string().required(standardRequiredText),
      has_suffered_from_slipped_disc: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_prolapsed_disc: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_other_back_problems: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_arthritis: Yup.string().required(standardRequiredText),
      has_suffered_from_other_form_of_inflammation: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_repetitive_strain_injury: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_white_finger_or_carpel_tunne_syndrome: Yup.string().required(
        standardRequiredText
      ),
      has_restricted_mobility_of_arms_hands_or_fingers: Yup.string().required(
        standardRequiredText
      ),
      has_restrictions_to_general_mobility: Yup.string().required(
        standardRequiredText
      ),
      has_been_disqualified_from_driving_due_to_ill_health: Yup.string().required(
        standardRequiredText
      ),
      is_registered_as_disabled: Yup.string().required(standardRequiredText),
      condition_details: Yup.string(),
    }),
    general: Yup.object().shape({
      has_had_serious_head_injury: Yup.string().required(standardRequiredText),
      has_suffered_from_nervous_disorders: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_depression: Yup.string().required(standardRequiredText),
      has_suffered_from_drug_alcohol_addiction: Yup.string().required(
        standardRequiredText
      ),
      has_suffered_from_diabetes: Yup.string().required(standardRequiredText),
      has_suffered_from_epilepsy: Yup.string().required(standardRequiredText),
      suffers_from_full_or_partial_deafness: Yup.string().required(
        standardRequiredText
      ),
      has_become_sensitized_to_chemical_substances: Yup.string().required(
        standardRequiredText
      ),
      has_had_accident_at_work: Yup.string().required(standardRequiredText),
      has_injured_fingers_hands_wrist_elbow_or_arm: Yup.string().required(
        standardRequiredText
      ),
      has_had_arm_operation_or_injections_to_wrist: Yup.string().required(
        standardRequiredText
      ),
      has_other_medical_related_matters: Yup.string().required(
        standardRequiredText
      ),
      condition_details: Yup.string(),
    }),
    consent_to_data_sharing: Yup.number().oneOf([1], standardRequiredText),
  }),
})
