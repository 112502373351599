import React, { useRef, useState } from "react"
import { Formik } from "formik"
import { Helmet } from "react-helmet"

import {
  submitForm,
  handleSubmitResponse,
  jsonToFormData,
} from "../../services"
import { dataURItoBlob } from "../../utils/form-data"

import { LCard } from "../../style/containers"

import EmploymentHistory from "../../components/EmploymentHistory"
import HealthAndMedicalQuestionnaire from "../../components/HealthAndMedicalQuestionnaire"
import Layout from "../../components/layout"
import PersonalDetails from "../../components/PersonalDetails"
import SButton from "../../components/button"
import Signature from "../../components/Signature"
import Form from "../../components/form/FormHelper"
import {
  initialValues,
  validationSchema,
} from "../../components/KieranAndZoeApplication"

const KieranAndZoeApplication = () => {
  const sigCanvas = useRef({})
  const [signatureDrawn, setSignatureDrawn] = useState()
  const [errorText, setErrorText] = useState([])

  const onSubmit = async (values, actions) => {
    if (signatureDrawn) {
      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/ false || !!document.documentMode

      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia

      let file

      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png")
      const blobContent = dataURItoBlob(dataURL)
      const formData = jsonToFormData(values)

      if (isEdge || isIE) {
        file = new Blob([blobContent], "signature.png", {
          type: "image/png",
        })
      } else {
        file = new File([blobContent], "signature.png", { type: "image/png" })
      }

      formData.append("signature", file)

      let res = await submitForm(formData, "")
      handleSubmitResponse(res, setErrorText)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Application Form</title>
      </Helmet>
      <LCard>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values, isSubmitting }) => (
            <Form>
              <h1>Application Form</h1>
              <h2>Personal details</h2>
              <PersonalDetails setFieldValue={setFieldValue} values={values} />
              <h2>Employment History</h2>
              <EmploymentHistory />
              <h2>Health and Medical Questionnaire</h2>
              <HealthAndMedicalQuestionnaire
                setFieldValue={setFieldValue}
                values={values}
              />
              <Signature
                signatureDrawn={signatureDrawn}
                setSignatureDrawn={setSignatureDrawn}
                sigCanvas={sigCanvas}
              />
              {errorText
                ? errorText.map(e => (
                    <Error style={{ marginTop: "1rem" }} key={e}>
                      {e}
                    </Error>
                  ))
                : null}
              <SButton type="submit" disabled={isSubmitting}>
                Submit
              </SButton>
            </Form>
          )}
        </Formik>
      </LCard>
    </Layout>
  )
}

export default KieranAndZoeApplication
